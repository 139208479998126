import type { FC } from 'react'
import React from 'react'

type Props = {
  mark: {
    href: string
  }
}

const ExternalLink: FC<Props> = ({ children, mark }) => {
  const shouldOpenInNewWindow = mark.href.startsWith('http')

  return shouldOpenInNewWindow ? (
    <a
      className="text-lg text-teal-400 hover:underline"
      href={mark.href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  ) : (
    <a className="text-lg text-teal-400 hover:underline" href={mark.href}>
      {children}
    </a>
  )
}

export default ExternalLink

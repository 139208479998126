import { Link } from 'gatsby'
import type { FC } from 'react'
import React from 'react'

type Props = {
  mark: {
    slug: {
      current: string
    }
  }
}

const InternalLink: FC<Props> = ({ children, mark }) => {
  return (
    <Link
      className="text-lg text-teal-400 hover:underline"
      to={mark.slug.current}
    >
      {children}
    </Link>
  )
}

export default InternalLink

import { graphql, useStaticQuery } from 'gatsby'
import type { FC } from 'react'
import React from 'react'
import { Helmet } from 'react-helmet'

type Props = {
  description?: string
  isBlogPost?: boolean
  lang?: string
  meta?: { name: string; content: string }[]
  title: string
}

const SEO: FC<Props> = ({
  description = '',
  isBlogPost,
  lang = 'en',
  meta = [],
  title,
}) => {
  const { sanitySiteSettings } = useStaticQuery(
    graphql`
      query SiteSettingsQuery {
        sanitySiteSettings {
          title
          description
          siteMetadata {
            keywords
          }
        }
      }
    `,
  )

  const metaDescription = description || sanitySiteSettings.description
  const defaultTitle = sanitySiteSettings.title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : ''}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: 'keywords',
          content: sanitySiteSettings.siteMetadata.keywords.join(', '),
        },
        {
          property: 'og:type',
          content: isBlogPost ? 'article' : 'website',
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

export default SEO

import ExternalLink from './ExternalLink'
import InternalLink from './InternalLink'
import BaseBlockContent from '@sanity/block-content-to-react'
import type { FC } from 'react'
import React from 'react'
import { Sanity } from 'types/sanity'

type BlockProps = {
  children: React.ReactNode
  node: {
    style: string
  }
}

const serializers = {
  marks: {
    internalLink: InternalLink,
    externalLink: ExternalLink,
  },
  types: {
    block(props: BlockProps) {
      switch (props.node.style) {
        case 'h1':
          return <h1 className="text-6xl">{props.children}</h1>

        case 'h2':
          return <h2 className="text-5xl">{props.children}</h2>

        case 'h3':
          return <h3 className="text-4xl">{props.children}</h3>

        case 'h4':
          return <h4 className="text-3xl">{props.children}</h4>

        default:
          return <p className="text-xl text-gray-500 my-4">{props.children}</p>
      }
    },
  },
}

type Props = {
  blocks: Sanity.Block[]
}

const BlockContent: FC<Props> = ({ blocks }) => (
  <BaseBlockContent blocks={blocks} serializers={serializers} />
)

export default BlockContent

import SanityImage from 'gatsby-plugin-sanity-image'
import type { FC } from 'react'
import React from 'react'
import { Sanity } from 'types/sanity'

type Props = {
  className?: string
  height?: number
  image: Sanity.ImageWithAlt
  width?: number
}

const Image: FC<Props> = ({ className, height, image, width }) => {
  return (
    <SanityImage
      alt={image.alt}
      asset={image.asset}
      className={className}
      hotspot={image.hotspot}
      crop={image.crop}
      height={height}
      width={width}
      __typename={null}
      _key={null}
      _type={null}
      sources={null}
    />
  )
}

export default Image

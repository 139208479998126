import Hours from './Hours'
import { MailIcon, PhoneIcon, PrinterIcon } from '@heroicons/react/outline'
import { graphql, Link, useStaticQuery } from 'gatsby'
import type { FC } from 'react'
import React from 'react'
import { Sanity } from 'types/sanity'

const Footer: FC = () => {
  const { siteSettings } = useStaticQuery(graphql`
    query FooterNav {
      siteSettings: sanitySiteSettings {
        _rawCompanyHours
        companyEmail
        companyFax
        companyName
        companyPhone
        nav {
          slug {
            current
          }
          title
        }
      }
    }
  `)

  const nav: Array<Sanity.Page> = siteSettings.nav
  const lastNavItem = nav[nav.length - 1]

  return (
    <footer className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row md:justify-between">
          <nav
            className="-mx-5 -my-2 flex flex-wrap justify-center"
            aria-label="Footer"
          >
            {nav.slice(0, -1).map(({ slug, title }) => (
              <div key={slug.current} className="px-5 py-2">
                <Link
                  to={`/${slug.current}`}
                  className="text-base text-gray-500 hover:text-gray-900"
                >
                  {title}
                </Link>
              </div>
            ))}
            <div className="px-5 py-2">
              <Link
                to={`/${lastNavItem.slug.current}`}
                className="font-medium text-teal-500 hover:text-teal-500"
              >
                {lastNavItem.title}
              </Link>
            </div>
          </nav>
          <div className="mt-20 md:mt-0 flex flex-col justify-center">
            <a href={`mailto:${siteSettings.companyEmail}`} className="flex">
              <div className="flex-shrink-0">
                <MailIcon
                  className="h-6 w-6 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3 text-base text-gray-500">
                <p>{siteSettings.companyEmail}</p>
              </div>
            </a>
            <a href={`tel:${siteSettings.companyPhone}`} className="flex mt-1">
              <div className="flex-shrink-0">
                <PhoneIcon
                  className="h-6 w-6 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3 text-base text-gray-500">
                <p>{siteSettings.companyPhone}</p>
              </div>
            </a>
            <a href={`tel:${siteSettings.companyFax}`} className="flex mt-1">
              <div className="flex-shrink-0">
                <PrinterIcon
                  className="h-6 w-6 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3 text-base text-gray-500">
                <p>{siteSettings.companyFax}</p>
                <div className="mt-6">
                  <Hours blocks={siteSettings._rawCompanyHours} />
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="mt-8 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between">
          <p className="mt-8 text-center text-base text-gray-400">
            &copy; {new Date().getFullYear()} {siteSettings.companyName} All
            rights reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer

import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import Logo from 'components/Logo'
import { graphql, Link, useStaticQuery } from 'gatsby'
import type { FC } from 'react'
import React, { Fragment } from 'react'
import { Sanity } from 'types/sanity'

const Header: FC = () => {
  const { siteSettings } = useStaticQuery(graphql`
    query HeaderSectionNav {
      siteSettings: sanitySiteSettings {
        companyName
        nav {
          slug {
            current
          }
          title
        }
      }
    }
  `)

  const nav: Array<Sanity.Page> = siteSettings.nav
  const lastNavItem = nav[nav.length - 1]

  return (
    <header>
      <Popover>
        {({ open }) => (
          <>
            <nav
              className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 sm:h-36"
              aria-label="Top"
            >
              <div className="w-full py-6 flex justify-center">
                <div className="flex items-center justify-between w-full md:w-auto">
                  <Link to="/">
                    <span className="sr-only">{siteSettings.companyName}</span>
                    <Logo className="h-28 w-auto sm:h-36" />
                  </Link>
                  <div className="-mr-2 flex items-center md:hidden">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-teal-500">
                      <span className="sr-only">Open main menu</span>
                      <MenuIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                  <div className="hidden ml-10 space-x-8 md:block">
                    {nav.map(({ slug, title }, index) => {
                      const isLast = index === nav.length - 1
                      return (
                        <Link
                          key={slug.current}
                          to={`/${slug.current}`}
                          className={`font-medium ${
                            isLast
                              ? 'text-teal-500 hover:text-teal-500'
                              : 'text-gray-500 hover:text-gray-900'
                          }`}
                        >
                          {title}
                        </Link>
                      )
                    })}
                  </div>
                </div>
              </div>
            </nav>

            <Transition
              show={open}
              as={Fragment}
              enter="duration-150 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                static
                className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
              >
                <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className="px-5 pt-4 flex items-center justify-between">
                    <div>
                      <Logo className="h-36 w-auto" />
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-teal-500">
                        <span className="sr-only">Close main menu</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="px-2 pt-2 pb-3 space-y-1">
                    {nav.slice(0, -1).map(({ slug, title }) => {
                      return (
                        <Link
                          key={slug.current}
                          to={`/${slug.current}`}
                          className="block font-medium px-3 py-2 rounded-md text-base text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                        >
                          {title}
                        </Link>
                      )
                    })}
                  </div>
                  <Link
                    to={`/${lastNavItem.slug.current}`}
                    className="block font-medium w-full px-5 py-3 text-center text-teal-500 bg-gray-50 hover:bg-gray-100"
                  >
                    {lastNavItem.title}
                  </Link>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </header>
  )
}

export default Header
